.about {
  padding: 40px 0;

  .container {
    width: 100%;
    margin: 0 auto;

    h1 {
      font-size: 30px;
      font-weight: 500;
      color: black;
      margin-bottom: 40px;
    }

    p {
      font-size: 20px;
      margin-bottom: 40px;
    }
  }
}
