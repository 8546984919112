.publisher-detail {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
  //background: white;

  .container {
    width: 100%;
    height: 100%;
  }
}
