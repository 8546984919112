.avatar-dropdown {
}

.dropdown-content {
  display: flex;
  flex-direction: column;
  width: 200px;
  background: white;
  border-radius: 0px;
  margin: 0;
  border: 1px solid white;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, .15);

  .menu-userinfo {
    display: flex;
    flex-direction: row;
    padding: 16px 10px;
    align-items: center;

    .menu-name {
      margin-left: 10px;
      font-size: 16px;
      font-weight: bold;
      color: #212529;
    }
  }

  .ant-menu-item {
    background: transparent;
    color: #212529;

    &:hover {
      background: #d0d0d0;
    }

    .menu-icon {
      width: 14px;
      height: 14px;
      margin-right: 10px;
    }

    span {
      font-weight: 400;
      color: #212529;
      font-size: 14px;
    }
  }
}
