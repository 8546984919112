.publisher-detail-content {
  .publisher-info {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    .publisher-name {
      font-size: 24px;
      font-weight: bold;
    }

    .publisher-action {
      margin-left: 30px;
    }
  }
}
