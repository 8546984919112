.search {
  .container {
    width: 100%;
    flex-direction: column;
    margin: 0 auto;
    padding: 40px 0;

    .search-title {
      font-size: 30px;
      color: black;
      padding: 0 10px 20px;
    }

    .loading-container {
      display: flex;
      width: 100%;
      height: 100%;
      min-height: 300px;
      align-items: center;
      justify-content: center;
    }
  }
}
