.login {
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;

  .container {
    width: 100%;
    margin: 0 auto;
    flex-direction: row;

    .login-container {
      display: flex;
      flex: 0 0 42%;
      max-width: 42%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 0 auto;
      background: white;
      box-shadow: 0 0 1px #ececec;
      padding: 60px 50px;

      .custom-logo {
        width: 70%;
        margin-bottom: 40px;
      }

      .welcome {
        display: flex;
        flex-direction: column;

        .welcome-title {
          font-size: 20px;
          font-weight: bold;
          color: #1e384e;
          margin-bottom: 10px;
        }

        .welcome-content {
          font-size: 13px;
          color: #6c757d;
        }
      }

      .buttons {
        margin-top: 30px;
        margin-bottom: 20px;
      }

      .other-methods {
        padding-bottom: 20px;
        border-bottom: 1px solid #dee2e6!important;

        .other-methods-title {
          font-size: 12px;
          color: #6c757d;
          margin-bottom: 20px;
        }

        .other-methods-content {
          display: flex;
          flex-direction: column;
          align-items: center;

          .facebook-btn {
            width: 100%;
            color: #ffffff;
            background: #3B5998;
            cursor: pointer;
            border-radius: 0px;
            font-size: 14px;
            padding: 10px 0px;
            border: 1px solid transparent;
          }
        }
      }

      .bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 20px 0 10px;

        .forget-password {
          display: flex;

          span {
            color: #6c757d;
          }
        }

        .register {
          display: flex;

          span {
            color: #6c757d;
          }
        }
      }
    }
  }
}
