.app-detail-content {
  width: 100%;
  display: flex;
  flex-direction: column;

  .detail-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;

    .offline-tag {
      width: 200px;
      height: 200px;
      position: absolute;
      pointer-events: none;

      .offline-box {
        width: 200px;
        height: 200px;
        background-color: transparent;
        overflow: hidden;
        position: relative;
      }

      .offline-content {
        background-color: #464a50;
        color: white;
        width: 100%;
        height: 40px;
        text-align: center;
        margin-left: -70px;
        margin-top: 10px;
        position: absolute;
        transform: rotate(-45deg);

        .offline-status {
          margin-top: 4px;
          line-height: 18px;
          font-size: 14px;
        }

        .offline-date {
          line-height: 15px;
          font-size: 12px;
        }
      }
    }

    .icon {
      width: 120px;
      height: 120px;
      border-radius: 16px;

      &.offline {
        -webkit-filter: grayscale(1);
        filter: gray;
        filter: grayscale(1);
      }
    }

    .info {
      display: flex;
      padding: 0px 20px;
      flex-direction: column;
      align-items: flex-start;

      .name {
        font-size: 24px;
        color: #000;
      }

      .publisher {
        font-size: 14px;
        font-weight: 500;
        margin-top: 6px;
      }
    }

    .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: flex-end;

      .appstore-url {
        position: relative;
        display: flex;

        .download {
          height: 3rem;
          width: 9rem;
          font-size: 0;

          &.app-store {
            background: url('/src/assets/images/appstore.svg') 0 0 no-repeat;
            background-size: 100%;
          }
        }
      }

      .actions {
        display: flex;
        flex-direction: row;
        margin-top: 30px;
        align-items: center;

        .action-share {
          width: 24px;
          height: 24px;
          margin-right: 20px;
        }

        .action-favorite {
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  .screenshot-container {
    width: 100%;
    margin-bottom: 20px;

    .swiper-container {
      overflow: hidden;
      width: 100%;
      display: flex;
      flex-direction: row;
      padding-bottom: 14px;

      .swiper-slide {
        width: auto;
        flex-shrink: 0;
        box-sizing: border-box;
        border: 1px solid #eee;
        border-radius: 10px;
        box-shadow: 0 0 1.5rem rgb(29 0 58 / 5%);
        overflow: hidden;

        img {
          display: block;
          height: 350px;
        }
      }
    }
  }

  .detail-column {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    align-items: flex-start;

    .detail-column-label {
      width: 100%;
      font-size: 30px;
      font-weight: bold;
      text-align: left;
      margin-bottom: 16px;
    }

    .detail-column-content {
      font-size: 18px;
      color: #717173;
    }
  }
}
