.account-center-security-home {
  .column-item {
    width: 100%;

    .column-item-content {
      width: 100%;
      padding: 0 20px;
      height: 88px;
      line-height: 88px;

      .column-item-title {
        display: inline-block;
        width: 40%;
        vertical-align: middle;

        .column-item-title-icon {
          display: inline-block;
          width: 20px;
          height: 20px;
          vertical-align: middle;

          &.success {
            background: url("../../../../assets/images/icon/ic_account_security_success.svg") no-repeat;
          }

          &.warning {
            background: url("../../../../assets/images/icon/ic_account_security_warning.svg") no-repeat;
          }

          &.error {
            background: url("../../../../assets/images/icon/ic_account_security_error.svg") no-repeat;
          }
        }

        p {
          display: inline-block;
          font-size: 14px;
          color: #21303E;
          text-align: center;
          margin: 0 0 0 10px;
          vertical-align: middle;
        }
      }

      .column-item-description {
        display: inline-block;
        width: 40%;
        font-size: 14px;
        color: #21303E;

        &.error {
          color: #E62B58;
        }
      }

      .column-item-action {
        display: inline-block;
        width: 20%;
        text-align: right;

        font-size: 14px;
        color: #21303E;
      }
    }
  }


  //ANTD自定义
  .ant-list-item {
    margin: 0;
    padding: 0;
  }

  .ant-list-split .ant-list-item {
    border-bottom: 1px solid #f0f0f0;
  }

  .ant-list-split .ant-list-item:last-child {
    border-bottom: 1px solid #f0f0f0;
  }
}
