.login,.account-center,.ant-modal-root {
  @labelColor: #21303E;
  @inputPlaceholderColor: #A1A1A1;
  @inputFocusBorderColor: #21303E;
  @inputErrorColor: #FF3333;
  @inputWarningColor: #faad14;
  // 按钮
  @buttonColor: #FFC700;
  @buttonHoverColor: #c19600;
  @buttonTextColor: #15191d;
  @buttonDisableColor: #DCDCDC;
  //
  @input-height: 49px;
  @btn-height: 49px;
  //
  @label-margin-left:10px;

  /**
    操作区域
  */
  .form-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .operating-message {
      font-size: 14px;
      color: #21303E;
      padding: 0;
      margin: 0;

      a {
        color: #854701;
        font-weight: bold;
      }
    }
  }

  /**
    自定义Antd Button
   */

  // 按钮
  .ant-btn {
    width: 100%;
    height: @btn-height;
  }

  .ant-btn-primary {
    color: @buttonTextColor;
    font-weight: bold;
    background: @buttonColor;
    border-color: @buttonColor;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 3px 6px rgba(255, 199, 0, 0.3);
    box-shadow: 0 3px 6px rgba(255, 199, 0, 0.3);
  }

  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    color: @buttonTextColor;
    background: @buttonHoverColor;
    border-color: @buttonColor;
  }

  .ant-btn-primary[disabled],
  .ant-btn-primary[disabled]:hover,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    background: @buttonDisableColor;
    border-color: @buttonDisableColor;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  /**
    自定义Antd Form和Input
  */

  .ant-form-item:first-child {
    margin-top: 20px;
  }

  .ant-form-item:nth-child(n+2) {
    margin-top: 20px;
  }

  .ant-form-item-control {
    /*flex-direction: column-reverse;*/
  }

  .ant-form-item {
    margin: 0;
  }

  .ant-form-item-has-success, .ant-form-item-has-error {
    padding: 0;
    margin: 0;
  }

  // 单纯的输入框
  .ant-form-item-control .ant-form-item-control-input-content > .ant-input {
    height: @input-height;
    padding: 0 20px;
    font-size: 14px;
  }


  // 带附加物的输入框
  .ant-form-item-control .ant-input-affix-wrapper {
    height: @input-height;
    padding: 0 20px;
    font-size: 14px;
  }

  /* Input：提示文字 placeholder */

  .ant-input::-moz-placeholder {
    opacity: 1;
  }

  .ant-input::-webkit-input-placeholder {
    color: @inputPlaceholderColor;
    font-size: 12px;
  }

  .ant-input:-ms-input-placeholder {
    color: @inputPlaceholderColor;
    font-size: 12px;
  }

  .ant-input::-ms-input-placeholder {
    color: @inputPlaceholderColor;
    font-size: 12px;
  }

  .ant-input::placeholder {
    color: @inputPlaceholderColor;
    font-size: 12px;
  }

  /* Input（带拓展的）：提示文字 placeholder */

  .ant-input-affix-wrapper::-moz-placeholder {
    opacity: 1;
  }

  .ant-input-affix-wrapper::-webkit-input-placeholder {
    color: @inputPlaceholderColor;
    font-size: 12px;
  }

  .ant-input-affix-wrapper:-ms-input-placeholder {
    color: @inputPlaceholderColor;
    font-size: 12px;
  }

  .ant-input-affix-wrapper::-ms-input-placeholder {
    color: @inputPlaceholderColor;
    font-size: 12px;
  }

  .ant-input-affix-wrapper::placeholder {
    color: @inputPlaceholderColor;
    font-size: 12px;
  }

  /* Input:外边框颜色 */

  .ant-input:hover {
    border-color: @inputFocusBorderColor;
    border-right-width: 1px !important;
  }

  .ant-input:focus,
  .ant-input-focused {
    border-color: @inputFocusBorderColor;
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: 3px 3px 0px 0px rgba(33, 48, 62, 1);
    box-shadow: 3px 3px 0px 0px rgba(33, 48, 62, 1);
    //-webkit-box-shadow: 0 0 0 2px rgba(33, 48, 62, 0.2);
    //box-shadow: 0 0 0 2px rgba(33, 48, 62, 0.2);
  }

  /* 带拓展Input:外边框颜色 */

  .ant-input-affix-wrapper:hover {
    border-color: @inputFocusBorderColor;
    border-right-width: 1px !important;
  }

  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    border-color: @inputFocusBorderColor;
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: 3px 3px 0px 0px rgba(33, 48, 62, 1);
    box-shadow: 3px 3px 0px 0px rgba(33, 48, 62, 1);
    //-webkit-box-shadow: 0 0 0 2px rgba(33, 48, 62, 0.2);
    //box-shadow: 0 0 0 2px rgba(33, 48, 62, 0.2);
  }

  /* Input的外边框颜色：错误的时候 */

  .ant-form-item-has-error .ant-input,
  .ant-form-item-has-error .ant-input-affix-wrapper,
  .ant-form-item-has-error .ant-input:hover,
  .ant-form-item-has-error .ant-input-affix-wrapper:hover {
    background-color: #fff;
    border-color: @inputErrorColor;
  }

  .ant-form-item-has-error .ant-input:focus,
  .ant-form-item-has-error .ant-input-affix-wrapper:focus,
  .ant-form-item-has-error .ant-input-focused,
  .ant-form-item-has-error .ant-input-affix-wrapper-focused {
    border-color: @inputErrorColor;;
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: 3px 3px 0px 0px rgba(255,51,51, 1);
    box-shadow: 3px 3px 0px 0px rgba(255,51,51, 1);
    //-webkit-box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
    //box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  }

  /* Input的外边框颜色：错误的时候，输入框不显示阴影，不然会出现阴影嵌套阴影的问题（重要） */

  .ant-form-item-has-error .ant-input-affix-wrapper input:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }


  /* FormItem 提示的位置*/

  .ant-form-item-explain {
    position: absolute;
    top: -30px;
  }

  .ant-form-item-explain,
  .ant-form-item-extra {
    min-height: 24px;
  }

  /* 拓展 */

  .ant-form-item-explain.ant-form-item-explain-error {
    color: @inputErrorColor;
  }

  .ant-form-item-explain.ant-form-item-explain-warning {
    color: @inputWarningColor;
  }

  .ant-form-item-has-feedback.ant-form-item-has-error {

  }

  /* FormItem 标题样式 */
  .ant-form-item-label {
    margin-left: @label-margin-left;
    color: @labelColor;
    font-size: 13px;
    opacity: 1;
    transition: opacity 0.1s;
  }

  .ant-form-item-label > label {
    font-size: 13px;
  }

  .ant-form-item-with-help .ant-form-item-label {
    opacity: 0;
    transition: opacity 0.1s;
  }

  /* 标题的星号不显示 */

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }

  /* 提示动画（进入/离开）*/
  @-webkit-keyframes antShowHelpIn {
    0% {
      -webkit-transform: translateY(5px);
      transform: translateY(5px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes antShowHelpIn {
    0% {
      -webkit-transform: translateY(5px);
      transform: translateY(5px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
  @-webkit-keyframes antShowHelpOut {
    to {
      -webkit-transform: translateY(5px);
      transform: translateY(5px);
      opacity: 0;
    }
  }
  @keyframes antShowHelpOut {
    to {
      -webkit-transform: translateY(5px);
      transform: translateY(5px);
      opacity: 0;
    }
  }
}

