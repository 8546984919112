.related-app-view {
  width: 100%;

  .app-item {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    overflow-y: hidden;
    overflow: hidden;
    //background: #fafafa;
    background: #fff;
    -webkit-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
    padding: 10px;

    .app-icon {
      width: 100%;
      height: auto;
      overflow: hidden;
      border-radius: 10px;
      text-align: center;
      vertical-align: middle;
      border-style: none;
      aspect-ratio: auto 300 / 300;
      margin-bottom: 20px;
    }

    .app-name {
      overflow: hidden;
      word-break: break-all;
      position: relative;

      p {
        font-size: 16px;
        word-break: break-all;
        max-height: 24px;
        line-height: 24px;
        font-weight: 500;
        font-style: normal;
        color: #1f1f25;
        word-break: break-all;
        overflow: hidden;
      }

      .text-end {
        background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(255, 255, 255, 1)));
        background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
        background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
        filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1, StartColorStr='#00ffffff', EndColorStr='#ffffff');
        top: 0;
        bottom: 0;
        height: 25px;
        max-height: 100%;
        pointer-events: none;
        position: absolute;
        right: 0;
        width: 60px;
      }
    }

    .app-cate {
      color: #333333;
      font-size: 16px;
      font-weight: 400;
    }
  }
}
