.qrcode-view {
  background: white;
  padding: 0px 10px;
  border-radius: 4px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .2);
  white-space: nowrap;

  .qrcode-view-title {
    margin: 0;
    padding: 8px 10px;
    text-align: center;

    .appstore-qrcode {
      font-size: 16px;
      color: #3A3A3A;
    }

    &:after {
      display: block;
      background-color: #d6d6d6;
      bottom: 0;
      content: "";
      height: 1px;
      margin-top: 8px;
    }
  }

  .qrcode-view-content {
    padding: 8px 16px;
    text-align: center;
  }
}
