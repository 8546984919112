.account-center-content-title {

  .title {
    font-size: 26px;
    padding-left: 20px;
    border-left: 5px solid black;
    margin-bottom: 30px;
  }
}

