.no-data-view {

  .container {
    width: 100%;
    flex-direction: column;
    margin: 0 auto;
    text-align: center;
    justify-content: center;

    .no-data-container {
      padding: 120px 0;

      .no-data-img {
        margin-bottom: 60px;
      }

      img {
        vertical-align: middle;
        border-style: none;
      }
    }
  }
}
