@header-nav-height: 44px;

html {
  font-size: 16px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Poppins;
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

p {
  font-family: Poppins;
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

//@media (min-width: 576px) {
//  .container {
//    max-width: 540px;
//  }
//}
//
//@media (min-width: 768px) {
//  .container {
//    max-width: 720px;
//  }
//}
//
//@media (min-width: 992px) {
//  .container {
//    max-width: 960px;
//  }
//}


@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}



