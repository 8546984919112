@content-color: #444444;

.filter-column {
  display: flex;
  flex-direction: column;

  &:nth-child(n+2) {
    margin-top: 20px;
  }

  &:first-child {
    margin-top: 0px;
  }

  .filter-column-label {
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 8px;
  }

  // 自定义antd
  .ant-radio-wrapper {

    span {
      font-size: 16px !important;
      color: @content-color;
    }

    .ant-radio {

    }
  }
}
