.footer {
  position: relative;
  width: 100%;
  height: 518px;
  background-image: url("/src/assets/images/footer/footer_image.png");
  padding: 100px 0px 73px 0px;

  .background-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
    //background-image: linear-gradient(180deg, #4B91ED 0%, #2F80ED 100%);
    background-image: linear-gradient(180deg, #6f71f8 0%, #5e60fa 100%);
    opacity: 0.9;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  }

  .elementor-shape-top {
    top: -1px;
  }

  .footer-logo {
    width: 240px;
    object-fit: contain;
  }

  .elementor-shape {
    overflow: hidden;
    position: absolute;
    left: 0;
    width: 100%;
    line-height: 0;
    direction: ltr;

    svg {
      position: relative;
      width: calc(100% + 1.3px);
      height: 66px;
      display: block;
    }

    .elementor-shape-fill {
      fill: rgb(248, 249, 250);
      -webkit-transform-origin: center;
      -ms-transform-origin: center;
      transform-origin: center;
      -webkit-transform: rotateY(0deg);
      transform: rotateY(0deg);
    }
  }

  .container {
    display: flex;
    margin-right: auto;
    margin-left: auto;
    position: relative;

    .language-container {
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      p {
        font-size: 18px;
        font-weight: 500;
        color: white;
      }

      ul {
        list-style: none;
        display: flex;
        flex-direction: row;

        li {
          cursor: pointer;
          font-size: 18px;
          font-weight: 500;
          color: white;
          padding: 0 10px;

          &.selected {
            text-decoration: underline;
          }
        }
      }
    }

    .container-row {
      width: 100%;
      display: flex;

      .container-left {
        width: 32%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 25px 0px 25px 0px;

        .footer-desc-container {
          margin: 35px 0px 15px 0px;
          text-align: left;

          .footer-desc {
            color: white;
            font-size: 18px;
            font-weight: 300;
            line-height: 1.7em;
          }
        }
      }

      .container-right {
        width: 68%;
        display: flex;
        flex-direction: row;
        padding: 25px 0px 25px 0px;

        .footer-links {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          margin: 0 auto;
          padding-left: 100px;

          .links-column {
            display: flex;
            flex-direction: column;
            flex: 1;
          }

          ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            strong {
              color: white;
              font-weight: 700;
              font-size: 20px;
            }

            li:first-child {
              color: #FFFFFF;
              font-family: "Poppins", Sans-serif;
              font-weight: 700;
              font-size: 20px;
              padding: 0;
              margin-bottom: 10px;
            }

            li {
              padding: 0px 0;
              text-align: left;

              a {
                display: inline-block;
                color: #F2F2F2;
                font-size: 18px;
                font-family: "Roboto", Sans-serif;
                padding-left: 0px;
                transition: all 0.2s;
                line-height: 46px;

                &:hover {
                  padding-left: 10px;
                  font-size: 18px;
                  //color: #fc6274;
                }
              }
            }
          }
        }
      }
    }
  }
}
