.app-list-item {
  display: block;
  width: 100%;
  background: white;
  border-radius: 2px;
  border: 1px solid #fafafa;
  //box-shadow: 0px 0px 27px 0px rgb(149 149 149 / 9%);
  transition: box-shadow .3s, border-color .3s;

  &:hover {
    border-color: transparent;
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, .16), 0 3px 6px 0 rgba(0, 0, 0, .12), 0 5px 12px 4px rgba(0, 0, 0, .09);
  }

  .offline-tag {
    width: 200px;
    height: 200px;
    position: absolute;
    pointer-events: none;

    .offline-box {
      width: 200px;
      height: 200px;
      background-color: transparent;
      overflow: hidden;
      position: relative;
    }

    .offline-content {
      background-color: #464a50;
      color: white;
      width: 100%;
      height: 40px;
      text-align: center;
      margin-left: -60px;
      margin-top: 20px;
      position: absolute;
      transform: rotate(-45deg);

      .offline-status {
        margin-top: 4px;
        line-height: 18px;
        font-size: 14px;
      }

      .offline-date {
        line-height: 15px;
        font-size: 12px;
      }
    }
  }

  .item-content {
    padding: 30px;

    .info {
      display: flex;
      flex-direction: row;

      .icon {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border: 1px solid #eee;
        border-radius: 10px;
      }

      .content {
        width: 100%;
        display: flex;
        margin-left: 10px;
        flex-direction: column;
        align-items: flex-start;
        font-size: 12px;
        font-weight: 500;
        color: #3A3A3A;
        padding: 0 0 6px;

        .name {
          color: #3A3A3A;
          font-size: 24px;
          font-weight: 500;
        }

        .publisher {
          font-size: 14px;
          font-weight: 500;
        }

        .other {
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: flex-end;

          .date-info {
            display: flex;
            flex-direction: row;
            align-content: center;
            font-size: 12px;

            .date {
            }

            .divider {
              width: 1px;
              margin: 3px 8px;
              background: #a0a0a0;
            }

            .release-date {
            }
          }
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 10px;

        .download {
          display: block;
          height: 3rem;
          width: 9rem;
          margin-bottom: .7rem;
          font-size: 0;

          &.app-store {
            background: url('/src/assets/images/appstore.svg') 0 0 no-repeat;
            background-size: 100%;
          }
        }

        .actions {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;

          .action-share {
            margin-right: 16px;
            cursor: pointer;
          }

          .action-favorite {
            cursor: pointer;
          }
        }
      }
    }

    .screenshot-container {
      width: 100%;
      margin-top: 10px;

      .swiper-container {
        overflow: hidden;
        width: 100%;
        display: flex;
        flex-direction: row;
        padding-bottom: 14px;

        .swiper-slide {
          width: auto;
          flex-shrink: 0;
          box-sizing: border-box;
          border: 1px solid #eee;
          border-radius: 10px;
          box-shadow: 0 0 1.5rem rgb(29 0 58 / 5%);
          overflow: hidden;

          img {
            display: block;
            max-width: 300px;
            max-height: 317px;
            min-width: 120px;
          }
        }
      }
    }
  }
}

.must-login-pop-view {
  display: flex;
  flex-direction: column;
  justify-content: center;

  a {
    background: transparent;
    border: none;
    box-shadow: none;
    color: #6a3093;
    text-align: center;
  }
}
