.coming-soon-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0;

  .image {
    width: 340px;
    height: auto;
    margin-bottom: 50px;
  }

  .desc {
    font-size: 24px;
    font-weight: 500;
    color: #3A3A3A;
  }
}
