.favorite-app-item {
  img {
    width: 100%;
    height: auto;
    border: 1px solid #eee;
    border-radius: 10px;
    margin-bottom: 6px;
  }

  .app-name {
    font-size: 14px;
    word-break: break-all;
    font-weight: 500;
    font-style: normal;
    color: #1f1f25;
    word-break: break-all;
    max-lines: 2;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
