.header {
  position: relative;
  width: 100%;
  background-color: white;
  //box-shadow: 0px 0px 1px #3A3A3A;
  padding: 20px 0;
  z-index: 1;

  .header-content {
    .container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      margin: 0 auto;
      flex-wrap: wrap;
      align-items: center;

      .left {
        flex: 0 0 16.666667%;
        max-width: 16.666667%;

        .custom-logo {
          width: 240px;
          object-fit: contain;
        }
      }

      .center {
        .menu {
          display: flex;
          flex-direction: row;
          list-style: none;
          color: #1f1f25;
          font-size: 18px;
          font-family: Poppins;
          margin: 0 auto;
          font-weight: 500;

          .menu-item {
            margin-right: 30px;

            a {
              color: #1f1f25;
              font-weight: 500;

              &:hover {
                color: #6a3093;
              }
            }

            &.selected {
              a {
                color: #6a3093;
                font-weight: 600;
              }
            }
          }
        }
      }

      .right {
        .login-btn {
          padding: 8px 30px;
          font-size: 16px;
        }
      }
    }
  }
}
