.page-loading-view {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  background: #fff;

  /*--------------------------------------------------------------
  # Preloader
  --------------------------------------------------------------*/

  #preloader {
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    display: table;
    text-align: center;
  }

  .spinner {
    margin: auto;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
  }

  @-webkit-keyframes uil-ripple {
    0% {
      width: 0;
      height: 0;
      opacity: 0;
      margin: 0 0 0 0;
    }

    33% {
      width: 44%;
      height: 44%;
      margin: -22% 0 0 -22%;
      opacity: 1;
    }

    100% {
      width: 88%;
      height: 88%;
      margin: -44% 0 0 -44%;
      opacity: 0;
    }
  }

  @-webkit-keyframes uil-ripple {
    0% {
      width: 0;
      height: 0;
      opacity: 0;
      margin: 0 0 0 0;
    }

    33% {
      width: 44%;
      height: 44%;
      margin: -22% 0 0 -22%;
      opacity: 1;
    }

    100% {
      width: 88%;
      height: 88%;
      margin: -44% 0 0 -44%;
      opacity: 0;
    }
  }

  @-webkit-keyframes uil-ripple {
    0% {
      width: 0;
      height: 0;
      opacity: 0;
      margin: 0 0 0 0;
    }

    33% {
      width: 44%;
      height: 44%;
      margin: -22% 0 0 -22%;
      opacity: 1;
    }

    100% {
      width: 88%;
      height: 88%;
      margin: -44% 0 0 -44%;
      opacity: 0;
    }
  }

  @keyframes uil-ripple {
    0% {
      width: 0;
      height: 0;
      opacity: 0;
      margin: 0 0 0 0;
    }

    33% {
      width: 44%;
      height: 44%;
      margin: -22% 0 0 -22%;
      opacity: 1;
    }

    100% {
      width: 88%;
      height: 88%;
      margin: -44% 0 0 -44%;
      opacity: 0;
    }
  }

  .uil-ripple-css {
    background: none;
    position: relative;
    width: 200px;
    height: 200px;
    margin: 0 auto;
  }

  .uil-ripple-css div {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    width: 0;
    height: 0;
    opacity: 0;
    border-radius: 50%;
    border-width: 12px;
    border-style: solid;
    -webkit-animation: uil-ripple 2s ease-out infinite;
    animation: uil-ripple 2s ease-out infinite;
  }

  .uil-ripple-css div:nth-of-type(1) {
    /*border-color: #fff;*/
  }

  .uil-ripple-css div:nth-of-type(2) {
    /*border-color: #fff;*/
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }
}
