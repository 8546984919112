@search-bg: #fafafa;
@search-border-color: #f5f5f5;
//@search-border-color: transparent;

.app-search-bar {
  width: 100%;
  background: white;
  padding: 15px;
  margin-bottom: 20px;

  .ant-input-group {
    background: @search-bg;
  }

  .ant-input {
    background: @search-bg;
    border: 1px solid @search-border-color;
  }

  .ant-btn {
    background: @search-bg;
    border: 1px solid @search-border-color;
    border-left-color: transparent;
    border-radius: 2px;
  }
}
