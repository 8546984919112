.filters-content {
  //position: sticky;
  //top: 10px;
  background: white;
  padding: 18px 20px;
  border-radius: 2px;
  border: 1px solid #fafafa;
  //box-shadow: 0px 0px 27px 0px rgb(149 149 149 / 9%);
  transition: box-shadow .3s, border-color .3s;
  margin-bottom: 18px;

  &:hover {
    border-color: transparent;
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, .16), 0 3px 6px 0 rgba(0, 0, 0, .12), 0 5px 12px 4px rgba(0, 0, 0, .09);
  }

  .filters-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 18px;
    padding-bottom: 18px;
    border-bottom: 1px solid #ccc;
    text-align: left;
  }
}
