.copyright-bar {
  position: relative;
  background: #fff;
  padding: 30px 0;

  .copyright-bar-content {
    p {
      margin-bottom: 0;
      line-height: 1;
      color: #6f6c7f;
      font-size: 16px;
      text-align: center;
    }
  }
}
