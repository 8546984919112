// 方案1
@bg-color: #FAFAFA;

// 方案2
//@bg-color: white;

.home-info-box {
  position: relative;
  background-color: @bg-color;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 90px 0px 90px 0px;

  .container {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 0 auto;

    .home-info-item {
      position: relative;
      display: flex;
      flex: 1;
      margin: 10px;
      background: white;

      .item-content {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        background: #fff;
        padding: 50px 40px;
        border-radius: 10px;
        overflow: hidden;
        z-index: 0;

        img {
          width: 200px;
          height: auto;
          object-fit: contain;
          align-self: center;
        }

        h3 {
          font-weight: 500;
          font-size: 22px;
          margin-bottom: 20px;
          font-weight: 600;
          align-self: center;
        }

        p {
          color: #717173;
          font-size: 18px;
          line-height: 30px;
          font-weight: 300;
          font-style: normal;
          color: #717173;
        }
      }
    }
  }
}
