.account-center-follow {
  .column-title {
    font-size: 20px;
    padding-left: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid black;
    font-weight: bold;
  }

  .content {

    .load-more {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 0;

      .load-more-btn {
        width: 200px;

        .ant-btn-primary {
          background: linear-gradient(to right, #6A3093, #A044FF);
          border-color: #A044FF;
          box-shadow: 0 3px 6px rgba(106, 48, 147, 0.3);
        }

        span {
          color: white;
        }
      }
    }
  }
}
