.top-charts {
  .container {
    margin: 0 auto;
    padding: 20px 0;

    .content-area {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .left-area {
        flex: 0 0 30%;
        max-width: 30%;
        padding-right: 20px;
      }

      .right-area {
        flex: 0 0 70%;
        max-width: 70%;

        .loading-container {
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center;
        }

        .result-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          background-color: white;

          .ant-list-item {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .load-more {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;

    .load-more-btn {
      width: 180px;
      height: 50px;

      .ant-btn-primary {
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, #6A3093, #A044FF);
        border-color: #A044FF;
        box-shadow: 0 3px 6px rgba(106, 48, 147, 0.3);
      }

      span {
        color: white;
      }
    }
  }
}
