.search-result-view {
  background: white;
  padding: 0 20px;

  .result-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: solid 1px #f3f3f4;
    padding: 20px 0;

    .result-title {
      font-size: 18px;
      font-weight: bold;
    }

    .result-count {
      font-size: 14px;
    }
  }

  .result-content {
    .result-item-game {
      display: flex;
      flex-direction: row;
      align-content: center;

      img {
        width: 60px;
        height: 60px;
        margin-right: 14px;
        border-radius: 8px;
      }

      a {
        font-size: 14px;
        color: black;
        text-align: center;
        align-self: center;
      }
    }

    .result-item-status {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      font-family: "Poppins", Sans-serif;
      color: rgba(0, 0, 0, 0.85);

      .status {
        font-size: 14px;
        font-weight: 400;
      }

      .offline-date {
        font-size: 13px;
        font-weight: 400;
      }
    }
  }
}
