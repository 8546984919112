#root {
  //text-align: center;
  /*display: flex;*/
  /*flex-direction: column;*/
  font-family: "Poppins", Sans-serif;
}

.page-limit {
  min-height: calc(100vh - 125px);
  min-width: 100%;
}

div,
p,
ul,
li,
h1,
h2,
button,
a {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

.container {
  display: flex;
  flex-direction: column;
}

html,
body {
  margin: 0;
  width: 100%;
  height: 100%;
  /*overflow: hidden*/
}

a,
button,
input {
  -webkit-tap-highlight-color: rgba(255, 0, 0, 0);
}

.page-container {
  width: 100%;
}

.page {
  position: relative;
  width: 100%;
  min-height: 100vh;
  min-width: 1140px;
  background: rgb(248, 249, 250);
  //background: #f3f8fb;
}

.row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.text-flow-ellipsis-multiple {
  /* 多余内容省略号处理-多行 */
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.text-flow-ellipsis-single {
  /* 多余内容省略号处理-单行 */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.common-btn {
  padding: 15px 50px;
  text-decoration: none;
  display: inline-block;
  color: #fff;
  border-radius: 8px;
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
  background: linear-gradient(to right, #6A3093, #A044FF);
  font-family: "Poppins", Sans-serif;

  &:hover {
    color: #fff;
  }
}

.card-hoverable {
  transition: box-shadow .3s, border-color .3s;
}

//设置图片不可以拖动
img {
  -webkit-user-drag: none;
}

// 设置文字不可以选中
.cannot-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


/*--------------------------------------------------------------
## fade-in effect
--------------------------------------------------------------*/
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
