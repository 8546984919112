.information-view {
  .information-item {
    display: flex;
    flex-direction: row;
    padding: 10px 0;

    .title {
      width: 160px;
      font-size: 16px;
      font-weight: bold;
      color: #717173;
    }
  }
}
