.publisher-detail-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  align-items: flex-start;

  .detail-column-label {
    width: 100%;
    font-size: 30px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 16px;
  }

  .detail-column-content {
    font-size: 18px;
    color: #717173;
  }
}
