.top-list-item {
  display: block;
  width: 100%;
  background: white;
  padding: 20px 30px;
  border-radius: 2px;

  .info {
    display: flex;
    flex-direction: row;

    .rank {
      width: 100px;
      line-height: 100px;
      text-align: center;
      align-items: center;
      font-size: 20px;
      max-lines: 1;
    }

    .icon {
      width: 100px;
      height: 100px;
      object-fit: cover;
      border: 1px solid #eee;
      border-radius: 10px;
      margin-left: 20px;
    }

    .content {
      width: 100%;
      display: flex;
      margin-left: 12px;
      flex-direction: column;
      align-items: flex-start;
      font-size: 12px;
      font-weight: 500;
      color: #3A3A3A;
      padding: 0 0 6px;

      .name {
        color: #3A3A3A;
        font-size: 24px;
        font-weight: 500;
      }

      .publisher {
        font-size: 14px;
        font-weight: 500;
      }

      .other {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: flex-end;

        .date-info {
          display: flex;
          flex-direction: row;
          align-content: center;
          font-size: 12px;

          .date {
          }

          .divider {
            width: 1px;
            margin: 3px 8px;
            background: #a0a0a0;
          }

          .release-date {
          }
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 10px;

      .download {
        display: block;
        height: 3rem;
        width: 9rem;
        margin-bottom: .7rem;
        font-size: 0;

        &.app-store {
          background: url('/src/assets/images/appstore.svg') 0 0 no-repeat;
          background-size: 100%;
        }
      }

      .actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .action-share {
          margin-right: 16px;
          cursor: pointer;
        }

        .action-favorite {
          cursor: pointer;
        }
      }
    }
  }

  .screenshot-container {
    width: 100%;
    margin-top: 10px;

    .swiper-container {
      overflow: hidden;
      width: 100%;
      display: flex;
      flex-direction: row;
      padding-bottom: 14px;

      .swiper-slide {
        width: auto;
        flex-shrink: 0;
        box-sizing: border-box;
        border: 1px solid #eee;
        border-radius: 10px;
        box-shadow: 0 0 1.5rem rgb(29 0 58 / 5%);
        overflow: hidden;

        img {
          display: block;
          max-width: 300px;
          max-height: 317px;
          min-width: 120px;
        }
      }
    }
  }
}
