.page-not-found {
  //background-color: white;

  .container {
    width: 100%;
    margin: 0 auto;
    flex-direction: column;

    .error-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 120px 0;
      text-align: center;

      .error-img {
        margin-bottom: 60px;
      }

      img {
        vertical-align: middle;
        border-style: none;
      }

      .error-content {
        text-align: center;

        h3 {
          font-size: 36px;
          margin-bottom: 10px;
          font-family: 'Poppins', sans-serif;
          color: #353c48;
          margin-top: 0px;
          font-style: normal;
          font-weight: 700;
        }

        .desc {
          font-size: 16px;
          font-weight: normal;
          line-height: 30px;
          color: #595959;
          margin-bottom: 30px;
        }
      }
    }
  }

  .error-404 {
    padding: 120px 0;
    text-align: center;

    span {
      color: #1f1f25;
      font-size: 172px;
      display: block;
      font-weight: bold;
    }

    h1 {
      font-weight: bold;
      margin-bottom: 30px;
      color: #1f1f25;
      font-size: 2.5rem;
      line-height: 1.2;
    }

    .desc {
      margin: 30px 0 60px;
      font-family: Poppins;
      line-height: 30px;
      font-weight: 300;
      font-style: normal;
      color: #717173;
      font-size: 18px;
    }
  }
}

