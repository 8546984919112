.ad-view {
  //position: sticky;
  //top: 10px;
  background: white;
  padding: 20px;
  border-radius: 2px;
  border: 1px solid #fafafa;
  //box-shadow: 0px 0px 27px 0px rgb(149 149 149 / 9%);
  transition: box-shadow .3s, border-color .3s;
  margin-bottom: 20px;

}

