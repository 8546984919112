.home-recent-view {
  background: white;
  padding: 100px 0px 120px 0px;

  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;

    .home-column-title {
      width: 70%;
      padding: 10px;
      margin: 0 auto;

      .title {
        text-align: center !important;

        h1 {
          font-size: 60px;
          font-weight: bold;
        }

        p {
          font-size: 20px;
          line-height: 32px;
          margin-top: 20px;
          font-weight: 300;
          font-style: normal;
          color: #717173;
        }
      }
    }

    .content {
      width: 100%;
      padding: 10px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;

      .grid-item {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;

        .app-item {
          margin-left: 15px;
          margin-right: 15px;
          background: #fafafa;
          padding: 10px;
          display: flex;
          border-radius: 10px;
          position: relative;

          .app-item-image {
            border-bottom: none;

            img {
              width: 110px;
              height: 110px;
              box-shadow: none;
              vertical-align: middle;
              aspect-ratio: auto 200 / 200;
              border-radius: 16px;
            }
          }

          .app-item-content {
            padding: 4px 20px;
            margin-top: 0;

            a h5 {
              font-size: 17px;
              font-weight: 400;
              font-style: normal;
              color: #1f1f25;
              line-height: 1.2;
              text-align: left;
              max-lines: 2;
              overflow: hidden;
            }

            .price {
              position: absolute;
              font-size: 16px;
              font-weight: 500;
              bottom: 10px;
              display: flex;
              color: #333;
            }
          }
        }
      }
    }
  }
}
