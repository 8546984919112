// 方案1
@bg-color: black;
@text-color: white;

// 方案2
//@bg-color: white;
//@text-color: black;

.home-discover-view {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  //height: 100vh;
  height: 920px;
  left: 0px;
  background: @bg-color;

  .background-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('/src/assets/images/home_show_overlay.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  }

  .banner-app {
    width: 100%;
    padding: 100px 0 150px;

    .container {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: row;
      margin: 0 auto;
      align-items: center;

      .left-content {
        display: flex;
        flex: 0 0 50%;
        width: 50%;
        max-width: 50%;
        margin-top: auto !important;
        margin-bottom: auto !important;

        .banner-content {
          width: 100%;
          color: white;
          text-align: left;

          .introduce-title {
            color: @text-color;
            font-size: 62px;
            font-weight: bold;
            margin-bottom: 20px;
            white-space: pre-wrap;
          }

          .introduce-content {
            color: @text-color;
            font-size: 22px;
            line-height: 34px;
            margin-bottom: 0;
            font-weight: 300;
            white-space: pre-wrap;
          }

          .banner-btn {
            padding: 15px 50px;
            margin-top: 50px;
            font-weight: 400;
            font-size: 18px;
          }
        }
      }

      .right-content {
        flex: 0 0 50%;
        max-width: 50%;
        margin-top: auto !important;
        margin-bottom: auto !important;

        .banner-icons {
          position: relative;

          .banner-icon {
            transition: 0.3s;
            position: absolute;
            float: left;
            width: 90px;
            border-radius: 20px;
            overflow: hidden;
            box-shadow: 0 1px 10px 0px rgba(0, 0, 0, 0.15);
          }

          .banner-icon {
            transition: 0.3s;
            position: absolute;
            float: left;
            width: 90px;
            border-radius: 20px;
            overflow: hidden;

            a {
            }

            img {
              width: 90px;
              height: 90px;
              object-fit: contain;
            }
          }

          .banner-icon:hover {
            transition: 0.3s;
            transform: scale(1.3);
          }

          .banner-icon:nth-child(1) {
            top: -230px;
            right: 350px;
            animation: fadeIn ease 4s;
          }

          .banner-icon:nth-child(2) {
            top: -185px;
            right: 0;
            animation: fadeIn ease 2s;
          }

          .banner-icon:nth-child(3) {
            top: -80px;
            right: 0;
            animation: fadeIn ease 5s;
          }

          .banner-icon:nth-child(4) {
            top: 30px;
            right: 0;
            animation: fadeIn ease 7s;
          }

          .banner-icon:nth-child(5) {
            top: 150px;
            right: 0;
            animation: fadeIn ease 2s;
          }

          .banner-icon:nth-child(6) {
            top: -230px;
            right: 110px;
            animation: fadeIn ease 3s;
          }

          .banner-icon:nth-child(7) {
            top: -120px;
            right: 110px;
            animation: fadeIn ease 8s;
          }

          .banner-icon:nth-child(8) {
            top: -10px;
            right: 110px;
            animation: fadeIn ease 3s;
          }

          .banner-icon:nth-child(9) {
            top: 110px;
            right: 110px;
            animation: fadeIn ease 4s;
          }

          .banner-icon:nth-child(10) {
            top: 225px;
            right: 350px;
            animation: fadeIn ease 7s;
          }

          .banner-icon:nth-child(11) {
            top: -180px;
            right: 230px;
            animation: fadeIn ease 2s;
          }

          .banner-icon:nth-child(12) {
            top: -62px;
            right: 230px;
            animation: fadeIn ease 6s;
          }

          .banner-icon:nth-child(13) {
            top: 50px;
            right: 230px;
            animation: fadeIn ease 2s;
          }

          .banner-icon:nth-child(14) {
            top: 160px;
            right: 230px;
            animation: fadeIn ease 8s;
          }

          .banner-icon:nth-child(15) {
            top: -117px;
            right: 350px;
            animation: fadeIn ease 3s;
          }

          .banner-icon:nth-child(16) {
            top: -5px;
            right: 350px;
            animation: fadeIn ease 5s;
          }

          .banner-icon:nth-child(17) {
            top: 110px;
            right: 350px;
            animation: fadeIn ease 2s;
          }

          .banner-icon:nth-child(18) {
            top: -40px;
            right: 470px;
            animation: fadeIn ease 9s;
          }

          .banner-icon:nth-child(19) {
            top: 80px;
            right: 470px;
            animation: fadeIn ease 7s;
          }

          .banner-icon:nth-child(20) {
            top: -160px;
            right: 470px;
            animation: fadeIn ease 3s;
          }
        }
      }
    }
  }
}
