.info-area {
  background: #fff;
  border-radius: 6px;
  padding: 10px 20px;
  border: 1px solid #ebebeb;
  margin-bottom: 30px;

  .left {
    display: flex;
    flex: 0 0 75%;
    max-width: 75%;
    flex-direction: row;
    align-items: center;

    .result-count {
      color: #717173;
      font-size: 18px;
      font-weight: 300;
    }
  }

  .right {
    flex: 0 0 25%;
    max-width: 25%;

    form {
      display: block;
      margin-top: 0em;
    }

    // 自定义antd
    .ant-select-selector {
      width: 100%;
      height: 45px !important;
      padding: 0 15px;
      border: none !important;
      background: #fafafa !important;
      -webkit-appearance: none;
      -moz-appearance: none;
      -o-appearance: none;
      color: black;

      .ant-select-selection-item {
        line-height: 45px;
        font-size: 16px;
        font-family: "Poppins", Sans-serif;
      }

      option {
        font-weight: normal;
        display: block;
        white-space: nowrap;
        min-height: 20px;
        padding: 0px 2px 1px;
      }
    }

    .ant-select-arrow {
      top: 16px;
      right: 20px;
    }
  }
}
