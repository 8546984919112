.account-center-menu {

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      width: 100%;
      height: 48px;
      line-height: 48px;
      margin: 10px 0;
      background: transparent;
      border-left: thick solid transparent;

      img {
        height: 18px;
        width: 18px;
        margin-left: 30px;
        vertical-align: middle;
      }

      span {
        margin-left: 16px;
        font-size: 14px;
        color: #121212;
      }

      &:hover {
        background: #f2f3f5;
        cursor: pointer;
        border-left: thick solid black;
      }

      &.selected {
        border-left: thick solid black;

        img {
        }

        span {
          color: black;
        }
      }
    }
  }
}

