@buttonDisableColor: #DCDCDC;

.account-center {
  width: 100%;
  position: relative;
  padding: 50px 0 100px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    margin-right: -15px;
    margin-left: -15px;

    .left-container {
      display: flex;
      flex-direction: column;
      flex: 0 0 25%;

      .left-container-inside {
        display: flex;
        flex-direction: column;
        background-color: white;
        box-shadow: 0 0 1.5rem rgb(29 0 58 / 5%);

        .user-info {
          padding: 30px;
          background: linear-gradient(to right bottom, rgb(85, 10, 224), rgb(90, 148, 236));
          display: flex;
          flex-direction: column;
          align-items: center;

          .user-name {
            font-weight: 500;
            font-size: 20px;
            margin-top: 10px;
            margin-bottom: 6px;
            color: white;
          }

          .user-desc {
            color: rgba(255, 255, 255, .5);
            margin-bottom: 20px;
          }

          .logout-btn {
            background: rgba(255, 255, 255, 0.2);
            border: none;
            color: #fff;
            font-size: 13px;
            text-transform: uppercase;
            padding: 5px 14px;
            cursor: pointer;
            border-radius: 0px;
          }
        }

        .menu {
          margin: 20px 0;
        }
      }
    }

    .content-container {
      position: relative;
      height: 100%;
      min-height: 600px;
      display: flex;
      flex-direction: column;
      flex-basis: 0;
      flex-grow: 1;
      overflow: hidden;
      margin-left: 30px;
      background-color: white;
      box-shadow: 0 0 1.5rem rgb(29 0 58 / 5%);

      .content-container-common {
        position: relative;
        height: 100%;
        width: 100%;
        padding: 20px;
        display: flex;
        flex-direction: column;

        .content {
          position: relative;
          width: 100%;
          height: 100%;
          min-height: 500px;
          display: flex;
          flex-direction: column;
        }
      }


      .buttons {
        position: absolute;
        bottom: 0;
        right: 0;

        .ant-btn-primary {
          background: linear-gradient(to right bottom, #550ae0, #5a94ec);
          border-color: #5a94ec;
          box-shadow: none;
          color: white;
          font-weight: normal;
          padding: 10px 50px;
          font-size: 14px;
        }

        .ant-btn-primary[disabled],
        .ant-btn-primary[disabled]:hover,
        .ant-btn-primary[disabled]:focus,
        .ant-btn-primary[disabled]:active {
          color: rgba(0, 0, 0, 0.25);
          background: @buttonDisableColor;
          border-color: @buttonDisableColor;
          text-shadow: none;
          -webkit-box-shadow: none;
          box-shadow: none;
        }
      }
    }
  }
}

