.back-to-top {
  position: fixed;
  bottom: 80px;
  //right: 80px;
  left: calc(50% + 570px + 50px);
  z-index: 1;
  display: none;
  transition: all 1s;

  .arrow-up {
    display: flex;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    cursor: pointer;
    //background: linear-gradient(to right, #4B91ED, #2F80ED);
    background: linear-gradient(to right,#6f71f8, #5e60fa);
    align-items: center;
    justify-content: center;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
  }
}
