.follow-publisher-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  .publisher-name {
    font-size: 14px;
    word-break: break-all;
    font-weight: 500;
    font-style: normal;
    word-break: break-all;
    max-lines: 2;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .publisher-last-release {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      width: 80px;
      height: 80px;
      border: 1px solid #eee;
      border-radius: 10px;
    }

    .publisher-last-release-info {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 10px;
      margin-right: 10px;

      .publisher-last-release-title {
        font-size: 10px;
      }

      .publisher-last-release-date {
        font-size: 12px;
      }
    }
  }
}
