.new-apps {
  .container {
    margin: 0 auto;
    padding: 20px 0;

    .content-area {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .left-area {
        flex: 0 0 30%;
        max-width: 30%;
        padding-right: 20px;
      }

      .right-area {
        flex: 0 0 70%;
        max-width: 70%;

        .loading-container {
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center;
        }

        .result-content {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }

  .pagination-container {
    margin-top: 40px;
  }

  // antd 修改
  .ant-pagination-prev, .ant-pagination-next {
    padding: 0;
    margin-left: 0;
    line-height: 48px;
    color: #595959;
    font-weight: 600;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    text-align: center;
    border: 2px solid #dcdcdc;
    margin-right: 12px;

    .ant-pagination-item-link {
      background-color: transparent;
      border: none;
      border-radius: 0px;
    }
  }

  .ant-pagination-item {
    padding: 0;
    margin-left: 0;
    line-height: 48px;
    color: #595959;
    font-weight: 600;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    text-align: center;
    margin-right: 12px;

    //
    font-size: 16px;
    //
    border: none;
    background: transparent;

    &:hover {
      background: #2F80ED;

      a {
        color: white;
      }
    }
  }

  .ant-pagination-item-active {
    font-weight: 500;
    background: #2F80ED;
    border-color: #1890ff;

    a {
      color: white;
    }
  }
}
